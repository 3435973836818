<template>
  <LineRecord v-if="type==2" :record-id="id"></LineRecord>
  <VosRecord v-else :record-id="id"></VosRecord>
</template>

<script>
import {ref, watch, onMounted, reactive, onUnmounted} from "vue";
import {useRoute} from "vue-router";
import VosRecord from "@/views/open/wechat/record/type/VosRecord.vue";
import LineRecord from "@/views/open/wechat/record/type/LineRecord.vue";

export default {
  name: "index",
  components: {LineRecord, VosRecord},
  created() {
    if (document) {
      document.title = '语音质检平台 - 质检记录';
    }
  },
  setup(props, context) {
    const route = useRoute();
    let id = ref('');
    let type = ref(1);//1:vos质检(默认);2:线路质检

    onMounted(() => {
      // console.log('wechat/record route.query',route.query,'  location.href', location.href)
      let query = route.query;
      id.value = query?.id;
      type.value = query?.type || 1;
    })

    return {
      id, type,
    }
  },
}
</script>

<style lang="scss" scoped>
</style>